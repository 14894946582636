var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": _vm.noLuggage ? require("@icons/no-luggage.svg") : require("@icons/luggage.svg")
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }