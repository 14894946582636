<template lang="">
  <b-img
    class="mr-25"
    blank-color="#ccc"
    style="height: 24px;"
    :src="noLuggage ? require(`@icons/no-luggage.svg`) : require(`@icons/luggage.svg`)"
  />
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    noLuggage: {
      type: [Boolean, String],
      default: false,
    },
  },
}
</script>
<style lang="">

</style>
